import Vue from 'vue'
import Router from 'vue-router'

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace

Router.prototype.push = function (location) {
  return routerPush.call(this, location).catch((error) => error)
}
Router.prototype.replace = function (location) {
  return routerReplace.call(this, location).catch((error) => error)
}

Vue.use(Router)

const routes = [
  /**
   * Main routes
   */
  {
    path: '/',
    meta: {
      title: 'Selia',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          title: 'Selia | Home',
          requiresAuth: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/home/Home.vue').then(
            (c) => c.default
          )
      },
      {
        path: '/patients',
        name: 'Patients',
        meta: {
          title: 'Selia | Patients',
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "patients" */ '../views/patients/PatientsList.vue'
          ).then((c) => c.default)
      },
      {
        path: '/agenda',
        name: 'Agenda',
        meta: {
          title: 'Selia | Agenda',
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "agenda" */ '../views/agenda/Agenda.vue'
          ).then((c) => c.default)
      },
      {
        path: '/my-office',
        name: 'Office',
        meta: {
          title: 'Selia | My Office',
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "office" */ '../views/office/Office.vue'
          ).then((c) => c.default)
      },
      {
        path: '/messages',
        name: 'Messages',
        meta: {
          title: 'Selia | Messages',
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "messages" */ '../views/chat/Chat.vue'
          ),
        props: (route) => ({
          members: route.query?.members ? JSON.parse(route.query.members) : null
        })
      }
    ]
  },
  /**
   * Stand-alone routes
   */
  {
    path: '/sign-in',
    name: 'SignIn',
    meta: {
      title: 'Selia | Sign In',
      requiresAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "signin" */ '../views/auth/SignIn.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    meta: {
      title: 'Selia | Notifications',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ '../views/notifications/Notifications.vue'
      )
  },
  {
    path: '/patients/detail/:id',
    name: 'PatientDetail',
    meta: {
      title: 'Selia | Patient Detail',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "patient-detail" */ '../views/patients/PatientDetail.vue'
      )
  },
  {
    path: '/patients/form/:patientId',
    name: 'PatientForm',
    meta: {
      title: 'Selia | Patient Form',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "patient-form" */ '../views/patients/PatientFormView.vue'
      )
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'Selia | Profile',
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/profile/Profile.vue')
  },
  {
    path: '/profile/about-me-form',
    name: 'AboutMe',
    meta: {
      title: 'Selia | About Me',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about-me-form" */ '../views/profile/pages/AboutMeForm.vue'
      )
  },
  {
    path: '/profile/therapy-style',
    name: 'TherapyStyle',
    meta: {
      title: 'Selia | Therapy Style',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "therapy-style-form" */ '../views/profile/pages/TherapyStyleForm.vue'
      )
  },
  {
    path: '/profile/availability-form',
    name: 'Availability',
    meta: {
      title: 'Selia | Availability',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "profile-availability-form" */ '../views/profile/pages/AvailabilityForm.vue'
      )
  },
  {
    path: '/profile/services-list',
    name: 'ServicesList',
    meta: {
      title: 'Selia | Services list',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "profile-services-list" */ '../views/profile/pages/ServicesList.vue'
      )
  },
  {
    path: '/specialist/:slug',
    name: 'SpecialistDetail',
    meta: {
      title: 'Selia | Specialists Detail',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "specialists-detail" */ '../views/directory/SpecialistDetail.vue'
      )
  },
  {
    path: '/specialists/directory',
    name: 'SpecialistsDirectory',
    meta: {
      title: 'Selia | Specialists Directory',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "specialists-directory" */ '../views/directory/SpecialistsDirectory.vue'
      )
  },
  {
    path: '/sessions/:id',
    name: 'Lobby',
    meta: {
      title: 'Selia | Session',
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "session" */ '../views/sessions/Session.vue')
  },
  {
    path: '/patients-search',
    name: 'PatientsSearch',
    meta: {
      title: 'Selia | Patients',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "patients-search" */ '../views/patients/PatientsSearch.vue'
      ).then((c) => c.default)
  },
  {
    path: '/appointments/detail/:id',
    name: 'AppointmentDetail',
    meta: {
      title: 'Selia | Appointment detail',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "appointment-detail" */ '../views/agenda/AppointmentDetail.vue'
      )
  },
  {
    path: '/appointments/create',
    name: 'AppointmentCreate',
    meta: {
      title: 'Selia | Appointment create',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "appointment-create" */ '../views/agenda/AppointmentCreate.vue'
      )
  },
  {
    path: '/reschedule/:id',
    name: 'Reschedule',
    meta: {
      title: 'Selia | Reschedule Appointment',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "reschedule" */ '../views/agenda/Reschedule.vue'
      ).then((c) => c.default)
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      title: 'Selia | Forgot Password',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/ForgotPassword.vue'
      ).then((c) => c.default)
  },
  {
    path: '/forgot-password/verification',
    name: 'VerificationCode',
    meta: {
      title: 'Selia | Verification Code',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/VerificationCode.vue'
      ).then((c) => c.default)
  },
  {
    path: '/forgot-password/change',
    name: 'ChangePassword',
    meta: {
      title: 'Selia | Change Password',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/ChangePassword.vue'
      ).then((c) => c.default)
  },
  {
    path: '/modify-password',
    name: 'ModifyPassword',
    meta: {
      title: 'Selia | Modify Password',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/ModifyPassword.vue'
      ).then((c) => c.default)
  },
  {
    path: '/settings/notifications',
    name: 'NotificationSettings',
    meta: {
      title: 'Selia | Bussiness selia',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "notifications-settings" */ '../views/settings/NotificationSettingsContainer.vue'
      ).then((c) => c.default)
  },
  {
    path: '/settings/localization',
    name: 'LocalizationSettings',
    meta: {
      title: 'Selia | Localization Settings',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "localization-settings" */ '../views/settings/LocalizationSettingsContainer.vue'
      ).then((c) => c.default)
  },
  {
    path: '/settings/developer',
    name: 'DeveloperSettings',
    meta: {
      title: 'Selia | Developer settings',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "developer-settings" */ '../views/settings/DeveloperSettingsContainer.vue'
      ).then((c) => c.default)
  },
  {
    path: '/form',
    name: 'Typeform',
    meta: {
      title: 'Selia | Forms',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "sign-up" */ '../views/forms/Typeform.vue'
      ).then((c) => c.default)
  },
  {
    path: '/medical-detail',
    name: 'MedicalDetail',
    meta: {
      title: 'Selia | MedicalDetail',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "medical-detail" */ '../views/medical-history/MedicalDetail.vue'
      ).then((c) => c.default)
  },
  {
    path: '/contract/patient/:patientId',
    name: 'InformedConsent',
    meta: {
      title: 'Selia | Informed Consent',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "informed-consent" */ '../views/contracts/InformedConsent.vue'
      ).then((c) => c.default)
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: {
      title: 'Selia | Documents',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "documents-list" */ '../views/documents/Documents.vue'
      ).then((c) => c.default)
  },
  {
    path: '/document/:key',
    name: 'Document',
    meta: {
      title: 'Selia | Document',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "document" */ '../views/documents/Document.vue'
      ).then((c) => c.default)
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

const updateAppMeta = (to, from) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el)
  )

  if (!nearestWithMeta) return

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    .forEach((tag) => document.head.appendChild(tag))
}

const handleAuthentication = (to, from, next) => {
  const isAuthenticated = Boolean(
    localStorage.getItem(
      `selia_${process.env.VUE_APP_AUTH_MODE.toLowerCase()}_access_token`
    )
  )

  let query = null

  if (to.fullPath) {
    query = {
      next: to.fullPath
    }
  }

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({
      name: 'SignIn',
      query
    })
  } else {
    next()
  }
}

router.beforeEach(async (to, from, next) => {
  updateAppMeta(to, from)
  handleAuthentication(to, from, next)
})

export default router
