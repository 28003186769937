<template>
  <div>
    <template v-if="showDialog.reschedule.open && !showDialog.reschedule.close">
      <RescheduleDialog
        :appointmentId="appointment.id"
        :reschedule="reschedule"
        :origin="originEnums.specialist"
        :lang="lang"
        :reason="reason"
        :checked="checked"
      >
        <template v-slot:content>
          <div class="margin-y">
            <Checkbox
              :text="config[lang].checkbox"
              type="fullcheck-gray"
              :checked="checked"
              @value="isChecked"
            />
          </div>
          <TextareaField
            :config="inputConfig"
            :label="config[lang].textarea"
            @change="setReason"
          />
        </template>
      </RescheduleDialog>
    </template>

    <template v-if="showDialog.cancel.open && !showDialog.cancel.close">
      <CancelDialog
        :appointmentId="appointment.id"
        :canceled_by="user.id"
        :origin="originEnums.specialist"
        :lang="lang"
        :cancel-reason="reason"
        btn-close-color="gray"
      >
        <template v-slot:content>
          <div
            class="body-big gray-60 margin-bottom-lg"
            v-text="$translations.dialogs.appointment.cancel.reason"
          />
          <TextareaField
            :config="inputConfig"
            :label="$translations.general['write-here']"
            @change="setReason"
          />
        </template>
      </CancelDialog>
    </template>

    <template v-if="showDialog.survey.open && !showDialog.survey.close">
      <SurveyDialog
        :config="surveyConfig"
        :button-text="$translations.buttons.close"
        :lang="lang"
      />
    </template>

    <template
      v-if="
        showDialog.callRecommendations.open &&
        !showDialog.callRecommendations.close
      "
    >
      <CallRecommendationsModal />
    </template>
  </div>
</template>

<script>
import {
  RescheduleDialog,
  CancelDialog,
  SurveyDialog,
  Checkbox
} from '@seliaco/red-panda'
import { TextareaField } from '@seliaco/sea-otter'
import { OriginEnums } from '@/types/origins'
import { mapGetters } from 'vuex'
import CallRecommendationsModal from '../modals/CallRecommendationsModal.vue'

export default {
  components: {
    RescheduleDialog,
    CancelDialog,
    SurveyDialog,
    TextareaField,
    Checkbox,
    CallRecommendationsModal
  },
  props: {
    appointment: Object,
    showDialog: Object,
    surveyConfig: Object
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    config () {
      return {
        en: {
          checkbox: 'Yes, we already talked about it',
          textarea: 'Reason for reschedule'
        },
        es: {
          checkbox: 'Sí, ya lo hablamos',
          textarea: 'Motivo de reagendamiento'
        }
      }
    },
    inputConfig () {
      return {
        label: this.config[this.$store.getters.language.lang].textarea,
        error: null,
        readonly: false,
        disabled: false
      }
    }
  },
  data () {
    return {
      originEnums: OriginEnums,
      lang: this.$store.getters.language.lang,
      reason: null,
      checked: false
    }
  },
  methods: {
    setReason (reason) {
      this.reason = reason
    },
    isChecked () {
      this.checked = !this.checked
    },
    reschedule () {
      this.$router.push({
        name: 'Reschedule',
        params: {
          id: this.appointment.id
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep .field-item
  min-height: 114px
  align-items: start
  &-label
    text-align: left
</style>
