<template>
  <div class="relative">
    <!-- backdrop -->
    <div v-on:click="$emit('close')" class="fixed bg-black h-screen w-screen top-0 left-0 right-0 bottom-0 z-50 opacity-60"></div>

    <div class="dialog-container">
      <!-- icon -->
      <div class="heading-display padding-all rounded-full mx-auto w-max margin-bottom" :class="iconClass" v-if="icon"></div>

      <!-- title -->
      <div class="heading text-center margin-bottom leading-tight" v-if="title" v-html="title"></div>

      <!-- subtitle -->
      <div class="body-big text-center margin-bottom leading-tight" v-if="subtitle" v-html="subtitle"></div>

      <!-- options -->
      <div :class="optionsClass" v-if="options && options.length > 0">
        <!-- <button v-for="(option, index) in options" :class="buttonClass(option.style)" :key="index" @click="option.action">
          {{ option.text }}
        </button> -->
        <Button v-for="(option, index) in options"
          :key="index"
          :text="option.text"
          type="outline"
          @clicked="option.action"
        />
      </div>

      <!-- custom content -->
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'

export default {
  name: 'DialogContainer',
  components: {
    Button
  },
  props: {
    icon: String,
    /**
     * DEFAULT | WARNING | DANGER
     */
    severity: String,
    title: String,
    subtitle: String,
    /**
     * {
     *   text: String
     *   style: OUTLINED | FILLED | DEFAULT,
     *   action: String
     * }
     */
    options: Array
  },
  methods: {
    buttonClass (style) {
      let classes = 'w-full padding-all content-title rounded-lg border'
      let color = 'purple'

      switch (this.severity) {
        case 'WARNING':
          color = 'yellow'
          break
        case 'DANGER':
          color = 'red'
          break
      }

      switch (style) {
        case 'OUTLINED':
          classes = `text-${color} border-${color} ${classes}`
          break
        case 'FILLED':
          classes = `text-white bg-${color} border-${color} ${classes} shadow-purple`
          break
        default:
          classes = `text-black-100 border-black-100 ${classes}`
          break
      }

      return classes
    }
  },
  computed: {
    iconClass () {
      let color = 'purple'
      let bg = 'purple-20'

      switch (this.severity) {
        case 'WARNING':
          color = 'yellow'
          bg = 'yellow-20'
          break
        case 'DANGER':
          color = 'red'
          bg = 'red-20'
          break
      }

      return `${this.icon} text-${color} bg-${bg}`
    },
    optionsClass () {
      return `flex gap-2 ${this.options.length === 2 ? 'flex-row' : 'flex-col'}`
    }
  }
}
</script>

<style lang="sass" scoped>
.dialog-container
  border-radius: 20px
  background: #fff
  padding: 24px
  width: calc(100vw - 32px)
  position: fixed
  bottom: 50%
  right: 50%
  transform: translate(50%, 50%)
  z-index: 60
</style>
