<template>
  <div id="app" class="bg-white min-h-screen w-full" v-bind:class="platform">
    <Loader />
    <router-view v-if="ready" />

    <chat-events
      v-if="user?.id"
      ref="chatEvent"
      :language="language.lang"
      :userId="user.id"
      :environment="environment"
      channelName=""
      @new-message="handlerNewMessage"
      @unread-messages="handlerUnreadMessages"
    ></chat-events>

    <!-- global components -->
    <GlobalDialog
      :appointment="appointment"
      :showDialog="showDialog"
      :survey-config="surveyConfig"
    />
  </div>
</template>

<script>
import Loader from '@/components/loaders/Loader'
import GlobalDialog from '@/components/dialogs/GlobalDialog'
import '@seliaco/giant-panda-chat'
import { mapGetters } from 'vuex'

export default {
  components: {
    Loader,
    GlobalDialog
  },
  data () {
    return {
      platform: null,
      ready: false,
      appointment: {},
      surveyConfig: {},
      showDialog: {
        reschedule: { open: Boolean, close: Boolean },
        cancel: { open: Boolean, close: Boolean },
        survey: { open: Boolean, close: Boolean },
        callRecommendations: { open: Boolean, close: Boolean }
      },
      environment: null
    }
  },
  created () {
    this.environment = process.env.VUE_APP_MODE === 'PROD' ? 'PRO' : 'DEV'
  },
  mounted () {
    this.loadEvents()
    this.platform = this.$store.getters.platform
    this.$store.commit('isLoading')
    this.ready = true
  },
  methods: {
    loadEvents () {
      this.$globalEvent.$on('modal/reschedule', (payload) => {
        this.appointment = payload.appointment
        this.showDialog.reschedule = payload.showDialog.reschedule
      })

      this.$globalEvent.$on('modal/cancel', (payload) => {
        this.appointment = payload.appointment
        this.showDialog.cancel = payload.showDialog.cancel
      })

      this.$globalEvent.$on('modal/survey', (payload) => {
        this.surveyConfig = payload.surveyConfig
        this.showDialog.survey = payload.showDialog.survey
      })

      this.$globalEvent.$on('modal/call-recommendations', (payload) => {
        this.showDialog.callRecommendations =
          payload.showDialog.callRecommendations
      })

      this.$globalEvent.$on('chat/disconnect', () => {
        this.$refs.chatEvent.disconnect()
      })
    },
    handlerUnreadMessages (e) {
      this.$store.commit('chat/SET_UNREAD_MESSAGES', e?.detail?.toString())
    },
    handlerNewMessage () {}
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      language: 'language'
    })
  }
}
</script>

<style lang="sass">
@import "@/styles/main"
</style>
