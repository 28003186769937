import { Appointments } from '@seliaco/red-panda'

export const AgendaStore = {
  namespaced: true,
  state: {
    currentMonth: '',
    appointmentsRaw: [],
    openModal: false,
    loading: false
  },
  getters: {
    appointmentsRaw (state) {
      return [...state.appointmentsRaw]
    },
    loading (state) {
      return state.loading
    },
    currentMonth (state) {
      return state.currentMonth
    },
    openModal (state) {
      return state.openModal
    }
  },
  mutations: {
    SET_APPOINTMENT_RAW (state, { appointmentsRaw }) {
      state.appointmentsRaw = [...appointmentsRaw]
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    },
    SET_CURRENT (state, { currentMonth }) {
      state.currentMonth = currentMonth
    },
    SET_OPEN_MODAL (state, { openModal }) {
      state.openModal = openModal
    }
  },
  actions: {
    async list ({ commit }, props) {
      commit('SET_LOADING', { loading: true })
      const { dateStart, dateEnd } = props
      commit('SET_CURRENT', { currentMonth: new Date(dateStart).getMonth() })

      const response = await Appointments.listAppointments(dateStart, dateEnd)

      commit('SET_APPOINTMENT_RAW', {
        appointmentsRaw: response
      })

      commit('SET_LOADING', { loading: false })
    }
  }
}
