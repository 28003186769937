<template>
  <div v-if="isLoading" class="loading-bg flex w-screen h-screen fixed top-0 z-50">
    <div class="self-center m-auto">
      <img class="animate-bounce margin-bottom-lg" :src="icon" alt="">
      <div class="text-center font-bold">
        Cargando...
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Loader',
  data () {
    return {
      icon: require('@/assets/images/icon.svg')
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading'
    })
  }
}
</script>

<style lang="css">
.loading-bg {
  background:
    radial-gradient(132.66% 50.8% at 4.27% 2.46%, #E8DDFD 0%, rgba(230, 220, 250, 0) 100%),
    radial-gradient(93.54% 48.65% at 108.53% 51.35%, #E5F3FC 0%, rgba(229, 243, 252, 0) 100%),
    linear-gradient(180deg, #E6DCFA 0%, #FFFFFF 100%)
}
</style>
