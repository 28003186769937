import { Settings } from '@seliaco/red-panda'

export const SettingsStore = {
  namespaced: true,
  state: {
    settings: {
      countries: [],
      videoCallVersion: null
    },
    loading: false
  },
  getters: {
    getSettings (state) {
      return { ...state.settings }
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_SETTINGS (state, payload) {
      state.settings = {
        ...payload
      }
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async get ({ commit, state }) {
      if (!state.settings) {
        commit('SET_LOADING', { loading: true })
      }

      const [countries, videoCallVersion] = await Promise.all([
        Settings.get('COUNTRIES').then((response) => response.parse_value),
        Settings.get('VIDEO_CALL_VERSION').then((response) => response.parse_value)
      ])

      commit('SET_SETTINGS', {
        countries,
        videoCallVersion
      })

      commit('SET_LOADING', { loading: false })
    }
  }
}
