import Vue from 'vue'
import Vuex from 'vuex'
import {
  AuthStore,
  getClientTimezone,
  SectionStore,
  NotificationsStore
} from '@seliaco/red-panda'
import { AvailabilityStore } from '@/store/modules/availability.store'
import { SettingsStore } from '@/store/modules/settings.store'
import { MedicalHistoryStore } from '@/store/modules/medical-history.store'
import { PatientGeneralStore } from '@/store/modules/patient-general.store'
import { AgendaStore } from '@/store/modules/agenda.store'
import { ChatStore } from '@/store/modules/chat.store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    query: {},
    platform: null,
    loading: false,
    hideLoadingScreen: false,
    language: {
      full: null,
      lang: null
    },
    isInitialized: false,
    isAuthenticated: false
  },
  getters: {
    route (state) {
      return state.route
    },
    isLoading (state) {
      return state.loading
    },
    signUpData (state) {
      return state.signUpData
    },
    language (state) {
      return state.language
    },
    query (state) {
      return state.query
    },
    isInitialized (state) {
      return state.isInitialized
    },
    isAuthenticated (state) {
      return state.isAuthenticated
    },
    platform (state) {
      return state.platform
    },
    userTimezone (state) {
      if (state.auth.user.time_zone) {
        return state.auth.user.time_zone
      } else {
        return getClientTimezone()
      }
    }
  },
  mutations: {
    isLoading (state, value = false) {
      state.loading = value
    },
    hideLoadingScreen (state) {
      state.hideLoadingScreen = true
    },
    updateLanguageCode (state, payload) {
      state.language = {
        full: payload.full,
        lang: payload.lang
      }
    },
    updatePlatform (state, payload) {
      state.platform = payload
    },
    authStateChanged (state, payload) {
      state.isAuthenticated = Boolean(payload)
      state.isInitialized = true
    },
    updateQuery (state, payload) {
      state.query = payload
    }
  },
  modules: {
    auth: AuthStore,
    availability: AvailabilityStore,
    section: SectionStore,
    settings: SettingsStore,
    medical: MedicalHistoryStore,
    patient: PatientGeneralStore,
    agenda: AgendaStore,
    notifications: NotificationsStore,
    chat: ChatStore
  }
})
