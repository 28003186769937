import { Flow } from '@seliaco/red-panda'

export const MedicalHistoryStore = {
  namespaced: true,
  state: {
    history: [],
    episode: null,
    appointment: null,
    loading: false
  },
  getters: {
    history (state) {
      return [...state.history]
    },
    episode (state) {
      return { ...state.episode }
    },
    loading (state) {
      return state.loading
    },
    appointment (state) {
      return { ...state.appointment }
    }
  },
  mutations: {
    SET_HISTORY (state, { history }) {
      state.history = [...history]
    },
    SET_EPISODE (state, { episode }) {
      state.episode = {
        ...episode
      }
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    },
    SET_APPOINTMENT (state, { appointment }) {
      state.appointment = appointment ? { ...appointment } : null
    }
  },
  actions: {
    async list ({ commit, rootState, state }, props) {
      commit('SET_LOADING', { loading: true })
      const { appointment } = state
      let param = null
      if (appointment) {
        param = {
          appointment_id: appointment?.id,
          patient_id: appointment?.patient.id,
          specialist_id: rootState.auth.specialist.id
        }
      }

      if (props?.patientId) {
        param = {
          patient_id: props?.patientId,
          specialist_id: rootState.auth.specialist.id
        }
      }

      const filters = {
        key: 'MEDICAL_HISTORY',
        userId: rootState.auth.user.id,
        meta: JSON.stringify(param)
      }

      const history = await Flow.list(filters, 'spe')

      if (history.length) {
        const answerDraft = history.filter((i) => i.status === 'DRAFT')

        commit('SET_EPISODE', {
          episode: answerDraft[0]
        })
      }

      commit('SET_HISTORY', {
        history
      })

      commit('SET_LOADING', { loading: false })
    },
    deleteEpisode ({ commit }, id) {
      commit('SET_LOADING', { loading: true })
      Flow.deleteFlow(id, 'spe').then(() => {})
      commit('SET_LOADING', { loading: false })
    }
  }
}
