import { SpecialistsPatients, SpeContracts } from '@seliaco/red-panda'

const validPatient = (patient) => {
  const valid = Boolean(
    patient?.age &&
      patient?.birthday &&
      patient?.dial_code &&
      patient?.dial_country_code &&
      patient?.documentNumber &&
      patient?.documentType &&
      patient?.email &&
      patient?.eps &&
      patient?.first_name &&
      patient?.gender &&
      patient?.last_name &&
      patient?.phone &&
      patient?.placeBirth &&
      patient?.placeResidence
  )

  return valid
}

export const PatientGeneralStore = {
  namespaced: true,
  state: {
    patient: null,
    meta: null,
    loading: false,
    isComplete: false
  },
  getters: {
    get (state) {
      return { ...state.patient }
    },
    meta (state) {
      return { ...state.meta }
    },
    loading (state) {
      return state.loading
    },
    isComplete (state) {
      return state.isComplete
    }
  },
  mutations: {
    SET_PATIENT (state, { patient }) {
      state.patient = { ...patient }
    },
    SET_META (state, { meta }) {
      state.meta = { ...meta }
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    },
    SET_IS_COMPLETE (state, { isComplete }) {
      state.isComplete = isComplete
    }
  },
  actions: {
    async getPatientProfileGeneral ({ commit }, { patientId, specialistId }) {
      const patient = await SpecialistsPatients.getProfileGeneral(patientId)
      const informedConsent = await SpeContracts.retrieveInformedConsent(patientId)

      commit('SET_PATIENT', { patient: { ...patient, informedConsent } })
      commit('SET_META', { meta: patient.meta })
      commit('SET_IS_COMPLETE', { isComplete: validPatient(patient.meta) })
      commit('SET_LOADING', { loading: false })
    },
    async updatePatient ({ commit }, { reqBody }) {
      const patient = await SpecialistsPatients.updatePatient(reqBody)

      commit('SET_PATIENT', { patient })
      commit('SET_META', { meta: patient.meta })
      commit('SET_LOADING', { loading: false })
    }
  }
}
