import { SpecialistsAvailabilityServices } from '@seliaco/red-panda'

const mapperBack = (value) => {
  return value === 'ONLINE' ? 'VIRTUAL' : 'IN_PERSON'
}

export const AvailabilityStore = {
  namespaced: true,

  state: {
    availability: null,
    modality: 'VIRTUAL',
    loading: false,
    availabilityDefault: null
  },

  getters: {
    get (state) {
      return state.availability
    },
    modality (state) {
      return state.modality
    },
    loading (state) {
      return state.loading
    }
  },

  mutations: {
    SET (state, availability) {
      state.availability = { ...availability }
    },
    SET_MODALITY (state, modality) {
      state.modality = mapperBack(modality)
    },
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    RESET (state) {
      state.availability = null
    },
    RESET_MODALITY (state) {
      state.modality = 'VIRTUAL'
    }
  },

  actions: {
    async setModality ({ commit, dispatch }, payload) {
      commit('SET_LOADING', true)
      commit('SET_MODALITY', payload.modality)
      dispatch('fetch')
    },
    async fetch ({ commit, getters }) {
      commit('SET_LOADING', true)
      const availability = await SpecialistsAvailabilityServices.get(getters.modality)

      if (getters.modality === 'IN_PERSON') {
        availability.timeInAdvance = availability.timeInAdvanceInPerson
      }

      commit('SET', availability)
      commit('SET_LOADING', false)
    },
    async update ({ commit, getters }, payload) {
      await SpecialistsAvailabilityServices.update(payload.body, getters.modality)
      commit('SET', payload.body)
    }
  }
}
