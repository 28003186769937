<template>
  <DialogContainer
    class="call-recommendation"
    :title="$translations.session.lobby.recommendations.title"
    icon="icon-video-outline-on text-purple"
    contentIconBackground="bg-purple-20"
    @closeDialog="closeModal"
  >
    <template v-slot:content>
      <div class="call-recommendation-modal">
        <div class="call-recommendation-modal_description body-big">
          {{ $translations.session.lobby.recommendations.text }}
        </div>
        <div class="call-recommendation-modal_content">
          <Button
            :text="$translations.session.lobby.recommendations.button"
            type="outline"
            @clicked="closeModal"
          />
        </div>
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import DialogContainer from './DialogContainer'
import { Button } from '@seliaco/red-panda'

export default {
  name: 'CallRecommendations',
  components: {
    DialogContainer,
    Button
  },
  methods: {
    closeModal () {
      this.$globalEvent.$emit('modal/call-recommendations', {
        showDialog: { callRecommendations: { open: false, close: true } }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.call-recommendation
  z-index: 999999
  ::v-deep .dialog-container
    max-width: 480px
  &-modal
    &_description
      text-align: center
    &_content
      display: flex
      flex-direction: column
      gap: 16px
      margin-top: 32px
</style>
