import '@seliaco/icons/style.css'
// import '../../red-panda/src/index.scss'
import '@seliaco/red-panda/src/index.scss'
import '@seliaco/sea-otter/src/index.scss'

/**
 * Red panda
 */
import {
  Auth,
  EventEmitterInstance as RedPandaEventEmitterInstance,
  TOPICS as RED_PANDA_TOPICS,
  Logger,
  Toast,
  Segment,
  $globalEvent,
  PushNotifications,
  Version
} from '@seliaco/red-panda'

/**
 * Capacitor
 */
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'
import { StatusBar, Style } from '@capacitor/status-bar'
import { SplashScreen } from '@capacitor/splash-screen'

/**
 * Assets
 */
import translationsFile from '@/assets/translations.json'
import languageCodes from '@/assets/language-codes.json'

/**
 * App
 */
import Vue from 'vue'
import WebApp from './App.vue'
import router from './router'
import store from './store'
import { version } from '../package.json'
import { CalendarLocale } from '@/constants/calendar-locale'

/**
 * Other libraries
 */
import smoothscroll from 'smoothscroll-polyfill'
import moment from 'moment'
import Hotjar from '@hotjar/browser'
import { H } from 'highlight.run'
import { handleRemoveLoader, handleShowLoader } from '../public/scripts/loader'
import * as luxon from 'luxon'
import * as Sentry from '@sentry/vue'

/**
 * Primefaces imports
 * https://www.primefaces.org/primevue/showcase-v2
 */
import 'primevue/resources/themes/nova/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import PrimeVue from 'primevue/config'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Calendar from 'primevue/calendar'
import InputMask from 'primevue/inputmask'
import Checkbox from 'primevue/checkbox'

const timedPromise = (promise) => {
  const startTime = performance.now()

  return promise.call().then((result) => {
    const endTime = performance.now()
    const elapsedMilliseconds = endTime - startTime

    if (elapsedMilliseconds > 10) {
      console.log(`${promise.name}: ${elapsedMilliseconds}ms`)
    }

    return result
  })
}
const authenticatedPromises = async (app, lang) => {
  const user = app.$store.getters['auth/user']
  const specialist = app.$store.getters['auth/specialist']

  const response = {}

  if (!user) {
    localStorage.removeItem(
      `selia_${process.env.VUE_APP_AUTH_MODE.toLowerCase()}_access_token`
    )
    app.$segment.reset()

    return {
      ...response,
      updatedLang: lang
    }
  }

  const promises = [
    {
      name: 'Set',
      call: async () => app.$store.dispatch('settings/get')
    },
    {
      name: 'Seg',
      call: async () => {
        const userTransform = {
          ...user,
          id: specialist.id
        }

        app.$segment.identifyAppUser(userTransform)
      }
    }
  ]

  const timedPromises = promises.map((promise) => timedPromise(promise))

  await Promise.all(timedPromises)

  return {
    ...response,
    language: user.language
  }
}
const promisesResolver = async (app, info, lang) => {
  let response = {
    updatedLang: lang
  }

  const promises = [
    {
      name: 'Ver',
      call: async () => Version.check('spe', info.platform, version)
    },
    {
      name: 'Pus',
      call: async () => {
        if (info.platform === 'web') {
          return
        }

        return PushNotifications.init(router)
      }
    },
    {
      name: 'Sta',
      call: async () => {
        if (info.platform === 'web') {
          return
        }

        try {
          await StatusBar.setStyle({ style: Style.Light })
          await StatusBar.setBackgroundColor({ color: '#ffffff' })
        } catch (e) {
          console.error('Unable to set status bar', e)
        }
      }
    },
    {
      name: 'Hig',
      call: async () => {
        const environment = process.env.VUE_APP_MODE

        if (info.platform === 'web' && environment === 'PROD_') {
          H.init('qe90zre1', {
            environment,
            version,
            networkRecording: {
              enabled: true,
              recordHeadersAndBody: true,
              urlBlocklist: [
                // insert full or partial urls that you don't want to record here
                // Out of the box, Highlight will not record these URLs (they can be safely removed):
                'https://www.googleapis.com/identitytoolkit',
                'https://securetoken.googleapis.com'
              ]
            }
          })
        }
      }
    },
    {
      name: 'Log',
      call: async () => {
        app.$logger.init({
          version
        })
      }
    },
    {
      name: 'Hot',
      call: async () => {
        const siteId = process.env.VUE_APP_HOTJAR_SITE_ID
        const hotjarVersion = process.env.VUE_APP_HOTJAR_VERSION

        Hotjar.init(siteId, hotjarVersion)
      }
    },
    {
      name: 'Seg',
      call: async () => {
        const params = {}

        if (location.search) {
          const parts = location.search.substring(1).split('&')
          for (let i = 0; i < parts.length; i++) {
            const nv = parts[i].split('=')
            if (!nv[0]) {
              continue
            }
            params[nv[0]] = nv[1] || true
          }
        }

        if (params.utm_source) {
          app.$store.commit('updateQuery', params)
        }

        app.$segment.init({
          platform: app.$platform,
          version: app.$version,
          marketing_channel: params.utm_campaign,
          ...app.$store.getters.query
        })
      }
    },
    {
      name: 'Aut',
      call: async () => {
        const admin = await app.$auth.init()

        response = {
          ...response,
          firestore: admin.firestore
        }
      }
    },
    {
      name: 'Usr',
      call: async () =>
        app.$store.dispatch('auth/fetchUser').then(async () => {
          const authenticatedPromisesRes = await authenticatedPromises(
            app,
            lang
          )

          response = {
            ...response,
            ...authenticatedPromisesRes
          }
        })
    }
  ]

  const timedPromises = promises.map((promise) => timedPromise(promise))

  await Promise.all(timedPromises)

  return response
}

const loadApp = async () => {
  console.time('App Load')

  await SplashScreen.show({
    autoHide: false
  })

  const info = await Device.getInfo()
  const language = await Device.getLanguageCode()

  let lang = language.value.split('-')[0]

  if (!['es', 'en', 'pt'].includes(lang)) {
    lang = 'es'
  }

  if (localStorage.getItem('languageStorage')) {
    lang = localStorage.getItem('languageStorage')
  }

  Vue.use(PrimeVue, { locale: CalendarLocale.es })
  Vue.component('InputText', InputText)
  Vue.component('Password', Password)
  Vue.component('Calendar', Calendar)
  Vue.component('InputMask', InputMask)
  Vue.component('Checkbox', Checkbox)
  Vue.config.productionTip = false
  Vue.prototype.$auth = Auth
  Vue.prototype.$translations = translationsFile[lang]
  Vue.prototype.$languageCodes = languageCodes
  Vue.prototype.$platform = info.platform
  Vue.prototype.$moment = moment
  Vue.prototype.$luxon = luxon
  Vue.prototype.$moment.locale(lang)
  Vue.prototype.$redPandaEventEmitter = RedPandaEventEmitterInstance
  Vue.prototype.$logger = Logger
  Vue.prototype.$toast = Toast
  Vue.prototype.$version = version
  Vue.prototype.$segment = Segment
  Vue.prototype.$globalEvent = $globalEvent
  Vue.prototype.$loader = { handleShowLoader, handleRemoveLoader }

  smoothscroll.polyfill()

  const app = new Vue({
    router,
    store,
    render: (h) => h(WebApp)
  })

  app.$store.commit('updatePlatform', info.platform)

  const { updatedLang, firestore } = await promisesResolver(app, info, lang)

  app.$store.commit('updateLanguageCode', {
    full: updatedLang,
    lang: updatedLang
  })

  Vue.prototype.$firestore = firestore
  Vue.prototype.$translations = translationsFile[updatedLang]
  Vue.prototype.$moment.locale(updatedLang)
  Vue.prototype.$moment.updateLocale(updatedLang, {
    meridiem: function (hour, minute, isLowerCase) {
      if (hour < 12) {
        return isLowerCase ? 'a.m.' : 'AM'
      } else {
        return isLowerCase ? 'p.m.' : 'PM'
      }
    }
  })

  Vue.config.errorHandler = (err, vm, info) => {
    if (err.message === 'ERR_CONNECTION_RESET') {
      console.warn('❌ Error: Connection reset', err)
    } else {
      console.warn('❌ Error: ', err)
    }
  }

  /**
   * Init Handle errors
   */
  app.$redPandaEventEmitter.on(RED_PANDA_TOPICS.HANDLE_ERRORS, (data) => {
    switch (data.error) {
      case 'TOKEN_EXPIRED':
      case 'UNAUTHORIZED':
        app.$router.replace('/sign-in').then(() => {
          app.$auth.signOut()
        })
        break
      default:
        break
    }
  })

  /**
   * listen to android tap back
   */
  App.addListener('backButton', (data) => {
    const path = router.currentRoute.path
    const query = router.currentRoute.query

    if (query.disableBack) {
      return
    }

    if (path === '/') {
      return App.exitApp()
    }

    router.go(-1)
  })

  /**
   * listen to deep links
   */
  App.addListener('appUrlOpen', (event) => {
    console.log('appUrlOpen', event)
    // Example url: https://specialists.selia.co/
    // slug = /sessions/demo
    const slug = event.url.split('.co').pop()

    // We only push to the route if there is a slug present
    if (slug) {
      router.push({
        path: slug
      })
    }
  })

  /**
   * Start sentry
   */
  if (process.env.VUE_APP_MODE === 'PROD') {
    console.log('Starting Sentry')

    Sentry.init({
      Vue,
      dsn: 'https://7a1b644b69f04d9a691aa5c547d107f4@o4507702387081216.ingest.us.sentry.io/4507732345880576',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration()
      ],
      // Performance Monitoring
      tracesSampleRate: 0.3, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      // Profiling
      profilesSampleRate: 1.0 // Profile 100% of the transactions. This value is relative to tracesSampleRate
    })
  }

  /**
   * App initialization completed
   */
  app.$mount('#app')
}

loadApp().then(async () => {
  await SplashScreen.hide()

  console.timeEnd('App Load')
})
