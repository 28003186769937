export const messagesByKey = (lang, key = 'DEFAULT') => {
  const messages = {
    es: {
      DEFAULT: [],
      NO_MESSAGE: []
    },
    en: {
      DEFAULT: [],
      NO_MESSAGE: []
    },
    pt: {
      DEFAULT: [],
      NO_MESSAGE: []
    }
  }
  return messages[lang][key]
}
